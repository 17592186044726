const colors = {
  black: '#000',
  white: '#FFF',
  mint: '#9DDCE2',
  navy: '#222E3C',
  grey: '#9CA8B5',
  borderGrey: '#E3E3E3',
  darkNavy: '#000a13',
  darkBackground: '#020913',
  darkNavyBackground: '#000914',
  textGrey: '#d1d1d1',
  darkGrey: '#717B86'
};

export default colors;
