import React, {Component} from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import {Row, Col} from 'styled-bootstrap-grid';
import Logo from '../../resources/images/logo.svg';
import LogoDark from '../../resources/images/logo-dark.svg'
import WhiteCart from '../../resources/images/ui/white-cart.svg';
import DarkCart from '../../resources/images/ui/dark-cart.svg';
import DarkClose from '../../resources/images/ui/close-dark.svg';
import DesktopNavBackground from '../../resources/images/desktop-nav-background.png';
import SocialMediaLinks from './SocialMediaLinks';
import posed from 'react-pose';
import WaveImg from '../../resources/images/ui/way-line.svg';
import LineImg from '../../resources/images/ui/blue-line.svg';
import colors from "../../styles/colors";
import { Link } from "gatsby";
import { connect } from "react-redux"
import { getItemsCount } from "../../store/reducers"
import Search from "./Search"
import Button from "../core/NewButton"


const DesktopNav = styled.div`
    display: none;
    @media (min-width: 1024px) {
        display: block;
     }
`;
const MobileNav = styled.div`
    display: block;

    @media (min-width: 1024px) {
        display: none;
     }
`;
const NavbarStyled = styled.div`
    height: 72px;
    width: 100%;
    background-color: transparent;
    color: ${colors.white};
    position: relative; 
    margin-top: 65px;
    z-index: 500;
`;
const NavLinkStyled = styled(Link)`
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props => props.dark === true ? "#222E3C" : "white"};
    ${fonts.gilroyBold};
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    margin: 0;
    text-decoration: none;
    letter-spacing: 2.77px;
     @media (min-width: 768px) {
        font-size: 12px;
     }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`;

const NavButtonStyled = styled.button`
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props => props.dark === true ? "#222E3C" : "white"};
    ${fonts.gilroyBold};
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    margin: 0;
    text-decoration: none;
    letter-spacing: 2.77px;
    cursor: pointer;
     @media (min-width: 768px) {
        font-size: 12px;
     }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`;

const StyledLink = styled.div`
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props => props.dark === true ? "#222E3C" : "white"};
    ${fonts.gilroyBold};
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    margin: 0;
    text-decoration: none;
    letter-spacing: 3px;
    cursor: pointer;
     @media (min-width: 768px) {
        font-size: 12px;
     }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`
const NavLinkText = styled.div`
    position: relative;
    -webkit-tap-highlight-color: transparent;
    :after {
        content: '';
        border-bottom: 3px solid transparent;
        display: block;
        position: absolute;
        right: 3px;
        left: 0;
        padding-top: 7px;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        :after {
            content: '';
            border-bottom: 3px solid ${colors.mint};
            ${({isItemCount}) => isItemCount && " border-bottom: 3px solid transparent"};
            display: block;
            position: absolute;
            right: 3px;
            left: 0;
            -webkit-tap-highlight-color: transparent;
        }
    }
`;
const LogoImage = styled.img`
    height: 100%;
    width: 72px;
    padding-left: 15px;
    padding-right: 15px;
`;
const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  width: ${props => props.fullwidth ? "100%" : ""};
  height: ${props => props.fullwidth ? "100%" : ""};
`;
const NavStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .active {
      div :after {
          border-bottom: 3px solid ${colors.mint};
      }
    }
`;
const MoreImgStyled = styled.div`
    padding-bottom: 0px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 25px;
`;
const CartMobileImg = styled.img`
    height: 22px;
    padding-left: 10px;
    padding-bottom: 10px;
`;
const NavBackground = styled.div`
    background: url(${DesktopNavBackground}) repeat-x;
    background-size: contain;
    background-position: 0 0;
    height:  250px;
    width: 100%;
`;
const OverlayStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height:  900px;
    width: 100vw;
    z-index: 400;
    
    @media (min-width: 768px) {
        top: 40px;
        height: 880px;
     }
`;
const MobileNavLinks = styled.div`
    padding-top: 60px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding-bottom: 40px;
     background: transparent;
`;
const MobileNavText = styled(Link)`
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    font-size: 17px;
    color: rgb(158, 168, 180);
    letter-spacing: 2px;
    text-decoration: none;
    margin-bottom: 20px;
    background: transparent;
    &:hover,
    &:active,
    &:focus,
    a:link, a:visited {
      -webkit-tap-highlight-color: transparent;
      background: transparent ;
      text-decoration: none ;
      outline: none;
      box-shadow: none;
      color: #24303B;
    }
`;

const DesktopNavLinks = styled.div`
    margin-top: 90px;
    padding-left: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: transparent;
`;
const DesktopNavText = styled(Link)`
   font-size: 45px; 
    padding-bottom: 15px;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    text-decoration: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #9CA8B5;
    text-indent: 4px;
    &:hover {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: ${colors.navy};
    }
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    color: ${colors.white};
    :before {
        transition: width 0.3s ease;
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        height: 45px;
        width: 0;
        color:  ${colors.navy};
        left: 0;
        top: 0;
    }
    :hover:before {
        width: 105%;
    }      
`;
const ContactBlock = styled.div`
    width: 100%;
    height: 50%;
    margin-bottom: ${props => props.top ? "-100px" : "0px"};
    padding-top: ${props => props.top ? "90px" : "0px"};
`;
const ContactText = styled.div`
    padding-top: 15px;
    font-size: 15px;
    color: ${colors.navy};
    ${fonts.gilroyRegular};
    transition: all 0.2s;
    &:hover,
    &:active,
    &:focus {
        color: ${colors.mint};
    }
`;
const ContactTitle = styled.div`
    font-size: 12px;
    color: #9CA8B5;
    ${fonts.gilroyBold};
    letter-spacing: 4px;
    text-transform: uppercase;
`;
const SocialLinksStyled = styled.div`
    position: relative;
    width: 100%;
    bottom: 70px;
`;
const Line1 = styled.div`
    background: url(${WaveImg}) no-repeat;
    height: 6px;
    width: 18px;
`;
const Line2 = styled.div`
    background: url(${WaveImg}) no-repeat;
    height: 6px;
    width: 18px;
`;
const Line3 = styled.div`
background: url(${WaveImg}) no-repeat;
    height: 6px;
    width: 18px;
`;
const Cross = styled.div`

    background: url(${DarkClose}) no-repeat;
    width: 14px;
    height: 14px;
    background-size: contain;
`;
const LinkStyled = styled(Link)`
      cursor: pointer;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
          text-decoration: none;
          outline: none;
          box-shadow: none;
          -webkit-tap-highlight-color: transparent;
      }
`;
const Email = styled.a`
    text-decoration: none;
    border: none;
    border-radius: none;
    &:hover,
    &:active,
    &:focus,
    a:link, a:visited {
        -webkit-tap-highlight-color: transparent;
        background: transparent;
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
    } 
`;

const AnimatedOverlay = posed.div({
  pre: {
    marginTop: '-100%'
  },
  init: {
    marginTop: 0,
    transition: {
      duration: 500,
      type: 'spring',
      damping: 20
    }
  }
});

const AnimatedDesktopOverlay = posed.div({
  pre: {
    opacity: 0
  },
  init: {
    opacity: 1,
    transition: {
      duration: 250,
      delay: 750
    }
  }
});

const AnimatedMobileOverlay = posed.div({
  pre: {
    opacity: 0
  },
  init: {
    opacity: 1,
    transition: {
      duration: 500,
      delay: 500
    }
  }
});

const DesktopOverlay = styled.div`
    display: none;
    padding-bottom: 50px;
    background: ${colors.white};
    padding-top: 25px;
    height: 630px;
    box-sizing: border-box;
    padding-top: 115px;
    @media (min-width: 768px) {
        display: block;
     }
`;
const MobileOverlay = styled.div`
    display: block;
    padding-top: 130px;
    padding-bottom: 50px;
    margin-top: -65px;
    background: ${colors.white};
    @media (min-width: 768px) {
        display: none;
     }
`;

const AnimatedOverlayStyled = styled(AnimatedOverlay)`
  position: relative;
  overflow: hidden;
  height: 100%;
  margin-top: -65px;
  padding-top: 65px;

  @media (min-width: 768px) {
    margin: 0;
    padding: 0;
   }
`;

const ButtonContainer = styled.div`
  width:126px;
  margin-top: 10px;
  
  a {
    height: 40px;
  }
  
  @media (min-width: 768px) {
    margin-top: 0;
  }
`


class HeaderNav extends Component {
  state = {
    isOverlayOpened: false,
    isMouseOverBurgerHover: false
  };

  handleOverlay = () => {
    this.setState({
      isOverlayOpened: !this.state.isOverlayOpened
    });
  };

  toggleHover = () => {
    this.setState({
      isMouseOverBurgerHover: !this.state.isMouseOverBurgerHover
    })
  };


  render() {
    const {isOverlayOpened} = this.state;
    const style = "dark";
    const delay = [' background 0.2s', ' background 0.4s', 'background 0.6s'];
    const {itemsCount, openCart} = this.props
    const fixedItemCount = itemsCount < 10 ? `0${itemsCount}` : itemsCount;
    return (<>
        <NavbarStyled overlayOpen={isOverlayOpened}>
          <MobileNav>
            <RowStyled>
              <ColStyled xs={4} sm={4}>
                <StyledLink onClick={this.handleOverlay} dark={isOverlayOpened}>
                  <MoreImgStyled>
                    {isOverlayOpened ?
                      <Cross/>
                      :
                      <>
                        <Line1 src={LineImg} delay={delay[0]} clicked={isOverlayOpened}/>
                        <Line2 src={LineImg} delay={delay[1]} clicked={isOverlayOpened}/>
                        <Line3 src={LineImg} delay={delay[2]} clicked={isOverlayOpened}/>
                      </>
                    }
                  </MoreImgStyled>
                  <NavLinkText> Menu</NavLinkText>
                </StyledLink></ColStyled>
              <ColStyled xs={4} sm={4}>
                <LinkStyled to={"/"}>
                  <LogoImage src={isOverlayOpened ? LogoDark : Logo}/>
                </LinkStyled>
              </ColStyled>
              <ColStyled xs={4} sm={4}><NavButtonStyled onClick={() => openCart(true)}
                dark={isOverlayOpened}><NavLinkText isItemCount={true}> {fixedItemCount} </NavLinkText><CartMobileImg
                src={isOverlayOpened ? DarkCart : WhiteCart}/></NavButtonStyled></ColStyled>
            </RowStyled>
          </MobileNav>
          <DesktopNav>
            <RowStyled>
              <ColStyled md={2}>
                <StyledLink onClick={this.handleOverlay} onMouseEnter={this.toggleHover}
                               onMouseLeave={this.toggleHover} dark={isOverlayOpened}>
                  <MoreImgStyled>
                    {isOverlayOpened ?
                      <Cross/>
                      :
                      <>
                        <Line1 src={LineImg} delay={delay[0]} clicked={isOverlayOpened}/>
                        <Line2 src={LineImg} delay={delay[1]} clicked={isOverlayOpened}/>
                        <Line3 src={LineImg} delay={delay[2]} clicked={isOverlayOpened}/>
                      </>
                    }
                  </MoreImgStyled>
                  <NavLinkText>{isOverlayOpened ? 'Close' : 'More'}</NavLinkText>
                </StyledLink>
              </ColStyled>
              <ColStyled md={3}>
                <NavStyled>
                <NavLinkStyled to={"/store"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Store</NavLinkText> </NavLinkStyled>
                  <NavLinkStyled to={"/shipmates"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Shipmates</NavLinkText>
                  </NavLinkStyled>
                  <NavLinkStyled to={"/happenings"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Happenings </NavLinkText>
                  </NavLinkStyled>
                </NavStyled>
              </ColStyled>
              <ColStyled md={2}>
                <LinkStyled to={"/"}>
                  <LogoImage src={isOverlayOpened ? LogoDark : Logo}/>
                </LinkStyled>
              </ColStyled>
              <ColStyled md={3}>
                <NavStyled>
                  <NavLinkStyled to={"/voyage"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Voyage</NavLinkText> </NavLinkStyled>
                  <NavLinkStyled to={"/foundation"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Foundation</NavLinkText>
                  </NavLinkStyled>
                  <NavLinkStyled to={"/beers"} dark={isOverlayOpened} activeClassName={'active'}><NavLinkText>Beers</NavLinkText> </NavLinkStyled>
                </NavStyled>
              </ColStyled>
              <ColStyled md={2}>
              <Search isOverlayOpened={isOverlayOpened} /> 
               {/* <ButtonContainer>
                 <Button to={"/invest"}>Invest Now</Button>
               </ButtonContainer> */}
              </ColStyled>
            </RowStyled>
          </DesktopNav>
        </NavbarStyled>
        <OverlayStyled style={{display: isOverlayOpened ? 'block' : 'none'}}>
          <AnimatedOverlayStyled initialPose={'pre'} pose={isOverlayOpened ? 'init': 'pre'} style={{position: 'relative', overflow: 'hidden'}}>
            <MobileOverlay>
              <AnimatedMobileOverlay initialPose={'pre'} pose={isOverlayOpened ? 'init': 'pre'}>
                <RowStyled>
                  <ColStyled>
                    <MobileNavLinks>
                      <MobileNavText to={"/store"}>Store</MobileNavText>
                      <MobileNavText to={"/shipmates"}>Shipmates</MobileNavText>
                      <MobileNavText to={"/happenings"}>Happenings</MobileNavText>
                      <MobileNavText to={"/voyage"}>Voyage</MobileNavText>
                      <MobileNavText to={"/foundation"}>Foundation</MobileNavText>
                      <MobileNavText to={"/beers"}>Beers</MobileNavText>
                      <MobileNavText to={"/brewery"}>Brewery</MobileNavText>
                      <MobileNavText to={"/introducing"}>Introducing</MobileNavText>
                      <MobileNavText to={"/contact"}>Ahoy</MobileNavText>
                      <MobileNavText to={"/we-can"}>Why the can</MobileNavText>
                      <MobileNavText to={"/futures"}>Futures</MobileNavText>

                      {/* <ButtonContainer>
                        <Button to={"/invest"}>Invest Now</Button>
                      </ButtonContainer> */}
                    </MobileNavLinks>
                  </ColStyled>
                </RowStyled>
                <RowStyled>
                  <ColStyled>
                    <SocialMediaLinks iconColor={style}/>
                  </ColStyled>
                </RowStyled>
              </AnimatedMobileOverlay>
            </MobileOverlay>
            <DesktopOverlay>
              <AnimatedDesktopOverlay initialPose={'pre'} pose={isOverlayOpened ? 'init': 'pre'}>
                <RowStyled>
                  <ColStyled md={5}>
                    <DesktopNavLinks>
                      <DesktopNavText to={"/brewery"} data-text="BREWERY">Brewery</DesktopNavText>
                      <DesktopNavText to={"/introducing"} data-text="INTRODUCING">Introducing</DesktopNavText>
                      <DesktopNavText to={"/contact"} data-text="AHOY">Ahoy</DesktopNavText>
                      <DesktopNavText to={"/we-can"} data-text="WHY THE CAN">Why the can</DesktopNavText>
                      <DesktopNavText to={"/futures"} data-text="FUTURES">Futures</DesktopNavText>
                    </DesktopNavLinks>
                  </ColStyled>
                  <ColStyled md={2}></ColStyled>
                  <ColStyled md={5}>
                    <RowStyled fullwidth>
                      <ColStyled md={6}>
                        <ContactBlock top>
                          <ContactTitle>Fancy a chat</ContactTitle>
                            <Email
                              href="mailto: ahoy@lostatsea.co.uk"><ContactText>ahoy@lostatsea.co.uk</ContactText></Email>
                        </ContactBlock>
                        <ContactBlock>
                          <ContactTitle>Want to stock us</ContactTitle>
                            <Email
                              href="mailto: shipmates@lostatsea.co.uk"><ContactText> shipmates@lostatsea.co.uk </ContactText></Email>
                        </ContactBlock>
                      </ColStyled>
                      <ColStyled md={6}>
                        <ContactBlock top>
                          <ContactTitle>Want to work here</ContactTitle>
                            <Email
                              href="mailto: crew@lostatsea.co.uk"><ContactText>crew@lostatsea.co.uk</ContactText></Email>
                        </ContactBlock>
                        <ContactBlock>
                          <ContactTitle>Want to support us</ContactTitle>
                            <Email
                              href="mailto: invest@lostatsea.co.uk"><ContactText> invest@lostatsea.co.uk </ContactText></Email>
                        </ContactBlock>
                      </ColStyled>
                    </RowStyled>
                    <SocialLinksStyled>
                      <SocialMediaLinks iconColor={style}/>
                    </SocialLinksStyled>
                  </ColStyled>
                </RowStyled>
              </AnimatedDesktopOverlay>
            </DesktopOverlay>
            <NavBackground/>
          </AnimatedOverlayStyled>
        </OverlayStyled>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    itemsCount: getItemsCount(state),
  }
}

export default connect(mapStateToProps)(HeaderNav)
