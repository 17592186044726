import CartActionTypes from '../../actionTypes/cart'
import Client from 'shopify-buy';
import {getCheckoutId, setCheckoutId, clearCheckout} from "../../../services/cart/checkout";
import {clearCart} from "../../../services/cart/cart";

const config = {
  shopifyShopDomain: process.env.SHOPIFY_SHOP_DOMAIN,
  shopifyAccessToken: process.env.SHOPIFY_ACCESS_TOKEN
};

const {shopifyShopDomain, shopifyAccessToken} = config;

if (!shopifyShopDomain || !shopifyAccessToken) {
  throw new Error('The shop name must be provided')
}

const client = Client.buildClient({
  domain: shopifyShopDomain,
  storefrontAccessToken: shopifyAccessToken
});

const initAction = () => async dispatch => {
  const isBrowser = typeof window !== 'undefined';
  
  const existingCheckoutId = isBrowser
    ? getCheckoutId()
    : null;
  
  let cart = {
    client: client
  };

  if (existingCheckoutId) {
    try {
      const checkout = await client.checkout.fetch(existingCheckoutId);
      
      if (!checkout.completedAt) {
        cart.checkout = checkout;
        
        dispatch({
          type: CartActionTypes.INIT_ACTION,
          payload: cart
        });
        
        return;
      } else {
        clearCart();
      }
    } catch (e) {
      clearCheckout();
      clearCart();
    }
  }
  
  const newCheckout = await client.checkout.create();

  cart.checkout = newCheckout;
  if (isBrowser) {
    setCheckoutId(newCheckout.id);
  }

  dispatch({ 
    type: CartActionTypes.INIT_ACTION, 
    payload: cart 
  });
};

export {
  initAction
}
