import CartActionTypes from '../../actionTypes/cart';
import {getCart} from "../../reducers";
import {getCartItems, updateCart} from "../../../services/cart/cart";

const updateItemAction = (lineItemId, quantity) => (dispatch, getState) => {

  if (!lineItemId || !quantity) {
    console.error('Product line item ID and quantity are required');
    return;
  }
  const {checkout, client, isUpdating} = getCart(getState());

  if (isUpdating === true) {
    return;
  }

  if (!checkout) {
    console.error('A checkout is required');

    return;
  }

  dispatch({
    type: CartActionTypes.UPDATING_ACTION
  });

  const isBrowser = typeof window !== 'undefined';
  let items = [];

  if (isBrowser) {
    items = getCartItems();
  }

  const checkoutId = checkout.id;

  let lineItem = {
    id: lineItemId,
    quantity: parseInt(quantity, 10)
  };

  const lineItemsToUpdate = [lineItem];

  return client.checkout
    .updateLineItems(checkoutId, lineItemsToUpdate)
    .then(checkout => {
      processCartUpdate(dispatch, items, checkout, lineItemId);
    });
};

function processCartUpdate(dispatch, items, checkout) {
  let cartData = updateCart(items, checkout);

  dispatch({
    type: CartActionTypes.UPDATE_ACTION,
    payload: {
      ...cartData,
      isUpdated: true
    }
  });

  window.setTimeout(function () {
    dispatch({
      type: CartActionTypes.UPDATED_ACTION,
      payload: {
        isUpdated: false
      }
    });
  }, 1500);
}

export {
  updateItemAction
}
