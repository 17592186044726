import {createGlobalStyle} from 'styled-components'
import GilroyBoldWoff from '../resources/fonts/gilroy-bold-webfont.woff';
import GilroyBoldWoff2 from '../resources/fonts/gilroy-bold-webfont.woff2';
import GilroyMediumWoff from '../resources/fonts/gilroy-medium-webfont.woff';
import GilroyMediumWoff2 from '../resources/fonts/gilroy-medium-webfont.woff2';
import GilroyRegularWoff from '../resources/fonts/gilroy-regular-webfont.woff';
import GilroyRegularWoff2 from '../resources/fonts/gilroy-regular-webfont.woff2';
import Swiss721BTBlackExtendedWoff from '../resources/fonts/swiss-721-black-extended-bt_43212-webfont.woff';
import Swiss721BTBlackExtendedWoff2 from '../resources/fonts/swiss-721-black-extended-bt_43212-webfont.woff2';
import reset from 'styled-reset'

const fontFace = (fontFamily, woff, woff2) => {
  return `
    @font-face {
        font-family: '${fontFamily}';
        src: url(${woff2}) format('woff2'),
        url(${woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
  `;
};


const GlobalStyle = createGlobalStyle`
    ${reset}
    
    *::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }

    *::-webkit-media-controls-play-button {
      display: none!important;
      -webkit-appearance: none;
    }
    
    *::-webkit-media-controls-start-playback-button {
      display: none!important;
      -webkit-appearance: none;
    }

    ${fontFace('GilroyBold', GilroyBoldWoff, GilroyBoldWoff2)}
    ${fontFace('GilroyMedium', GilroyMediumWoff, GilroyMediumWoff2)}
    ${fontFace('GilroyRegular', GilroyRegularWoff, GilroyRegularWoff2)}
    ${fontFace('SwissBlackExtended', Swiss721BTBlackExtendedWoff, Swiss721BTBlackExtendedWoff2)}
    
    body {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
