import React, {useState} from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import Compass from "../../resources/images/ui/compass-navbar-hero.svg"
import DarkCompass from "../../resources/images/ui/compass-dark.svg"
import { navigate } from "../../../.cache/gatsby-browser-entry"

const CompassStyled = styled.img`
  padding-bottom: 5px;
  padding-right: 10px;
  position: absolute;
  top: 0;
  left: 0;
`

const Form = styled.form`
  position: relative;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  padding-left: 30px;
  padding-top: 5px;
  background: transparent;
  color: ${props => (props.dark === true ? "#222E3C" : "white")};
  ${fonts.gilroyBold};
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  ::placeholder {
    color: ${props => (props.dark === true ? "#222E3C" : "white")};
  }
  :-ms-input-placeholder{
    color: ${props => (props.dark === true ? "#222E3C" : "white")};
  }
  ::-ms-input-placeholder{
    color: ${props => (props.dark === true ? "#222E3C" : "white")};
  }
  @media (min-width: 768px) {
    font-size: 12px;
  }
`

const Search = (props) => {
  const [q, setQ] = useState("")
  const {isOverlayOpened} = props
  let searchQuery = q

  const handleChange = (e) => {
    setQ(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate(`/store?q=${q}`)
    setQ("")
  }

  return (
    <Form action={"/search"} method={"GET"} onSubmit={handleSubmit} autoComplete="off">
      <CompassStyled src={isOverlayOpened ? DarkCompass : Compass} />
      <SearchInput name={"q"} placeholder={"Search"} value={searchQuery} onChange={handleChange} dark={isOverlayOpened}/>
    </Form>
  )
}

export default Search
