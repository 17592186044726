import React, {Component} from 'react';
import styled from 'styled-components';
import SocialMediaLinks from '../home/SocialMediaLinks';
import {Row, Col} from 'styled-bootstrap-grid';
import SignUpForm from './SignUpForm';
import fonts from '../../styles/fonts';
// import NTLogo from '../../resources/images/logos/nt-logo.svg';
import LifeboatsLogo from '../../resources/images/logos/lifeboats-logo.svg';
import SLogo from '../../resources/images/logos/site-by-logo.svg';
import TopOverlay from './OverlayTop';
// import FooterVideoPoster from '../../resources/videos/footer-video-poster.jpg';
import AnimatedLine from "./AnimatedLine"
import { Link } from "gatsby"
import colors from "../../styles/colors"
import ExternalLink from './ExternalLink';

const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
    @media(min-width: 768px) {
        display: ${props => props.mobileonly ? "none" : "block"}
    }
    @media(max-width: 768px) {
        display: ${props => props.desktoponly ? "none" : "block"}
    }
`;
const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  width: ${props => props.fullwidth ? "100%" : ""};
  position: relative;
  z-index: 5;
`;
const BackgroundStyled = styled.div`
    position: relative;
    height: 716px;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
    @media(min-width: 768px) {
        height: 830px;
    }
`;
const BackgroundImageStyled = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;
const SocialMediaLinksStyled = styled.div`
  display: none;
  @media (min-width: 992px) {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-around;
  }
`;
const FooterTitle = styled.h3`
    text-transform: uppercase;
    color: ${colors.white};
    ${fonts.swissBlackExtended};
    font-size: 24px;
    letter-spacing: 3px;
    padding-bottom: 30px;
    @media(min-width: 768px) {
        font-size: 44px;
        padding-bottom: 40px;
    }
`;
const FooterSubtitle = styled.div`
    color: ${colors.white};
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    padding-bottom: 40px;
    @media(min-width: 768px) {
        padding-bottom: 45px;
    }
`;
const FooterNavLinks = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    padding-right: ${props => props.right ? "40px" : "0px"};
    @media (max-width: 768px) {
        padding-bottom: ${props => props.bottom ? "0" : "30px"};
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
`;
const FooterNav = styled(Link)`
    color: ${colors.white};
    ${fonts.gilroyBold};
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 3px;
    -webkit-tap-highlight-color: transparent;
    @media(max-width: 768px) {
        margin-bottom:  30px;
    }
    position: relative;
    :after {
        content: '';
        border-bottom: 3px solid transparent;
        display: block;
        position: absolute;
        right: 3px;
        left: 0;
        padding-top: 7px;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
        :after {
            content: '';
            border-bottom: 3px solid ${colors.mint};
            display: block;
            position: absolute;
            right: 3px;
            left: 0;
        }
    }
`;
const SignUpFormStyled = styled.div`
    padding-bottom: 5px;
    @media(min-width: 768px) {
        padding-bottom: 40px;
    }
`;
const FrameStyled = styled.div`
    padding-bottom: 35px;
    @media (min-width: 768px) {
        padding-bottom: 50px;
    }
`;
const LifeBoatsImg = styled.img`
    @media (max-width: 768px) {
        height: 31px;
    }
`;
// const NTImg = styled.img`
//     @media (max-width: 768px) {
//         height: 47px;
//     }
// `;
const SImg = styled.img`
    @media (max-width: 768px) {
        height: 24px;
    }
`;
const VideoBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  padding: 0;
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100vw;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 250vh;
  }
`;

class Footer extends Component {

  render() {
    const footerVideo = 'https://player.vimeo.com/video/495755645'
    return (
      <BackgroundStyled>
        <BackgroundImageStyled>
          <RowStyled>
            <ColStyled>
              <AnimatedLine/>
            </ColStyled>
            <ColStyled><FooterTitle>Never miss a wave</FooterTitle></ColStyled>
            <ColStyled>
              <FooterSubtitle>
                <p>Love our products? Love the British coast?</p>
                <p>Then join the LAS Crew, and never miss a thing.</p>
              </FooterSubtitle>
            </ColStyled>
            <ColStyled><SignUpFormStyled><SignUpForm/></SignUpFormStyled></ColStyled>

            <ColStyled><FrameStyled>
              <iframe src="https://open.spotify.com/embed/playlist/3DbuRz2EwYM1znENa0cVIZ" width="300" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media" />
            </FrameStyled></ColStyled>
          </RowStyled>
          <RowStyled>
            <ColStyled lg={3} desktoponly>
              <FooterNavLinks>
                <SocialMediaLinksStyled>
                  <SocialMediaLinks footer/>
                </SocialMediaLinksStyled>
              </FooterNavLinks>
            </ColStyled>
            <ColStyled mobileonly>
              <FooterNavLinks>
                <ExternalLink href={"https://rnli.org/"}>
                  <LifeBoatsImg src={LifeboatsLogo}/>
                </ExternalLink>
                <ExternalLink href={"https://societystudios.co.uk"}>
                  <SImg src={SLogo}/>
                </ExternalLink>
              </FooterNavLinks>
            </ColStyled>
            <ColStyled desktoponly lg={1}></ColStyled>
            <ColStyled lg={4}>
              <FooterNavLinks bottom>
                <FooterNav to={"/futures"}>Futures</FooterNav>
                <FooterNav to={"/contact"}>Ahoy</FooterNav>
                <FooterNav to={"/legals"}>Legals</FooterNav>
                <FooterNav to={"/contact"}>Sell our beer</FooterNav>
              </FooterNavLinks>
            </ColStyled>
            <ColStyled desktoponly lg={1}></ColStyled>
            <ColStyled lg={3} desktoponly>
              <FooterNavLinks right>
                <ExternalLink href={"https://rnli.org/"}>
                  <LifeBoatsImg src={LifeboatsLogo}/>
                </ExternalLink>
                <ExternalLink href={"https://societystudios.co.uk"}>
                  <SImg src={SLogo}/>
                </ExternalLink>
              </FooterNavLinks>
            </ColStyled>
          </RowStyled>
          <TopOverlay/>
          <VideoBackground>
            <iframe src={`${footerVideo}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
                title="Lost At Sea Footer"
                frameBorder="0"
                allow="fullscreen; autoplay"
                allowFullScreen={true}/>
          </VideoBackground>
        </BackgroundImageStyled>
      </BackgroundStyled>
    )
  }
}


export default Footer;
