import React, { useEffect } from "react"
import styled, {keyframes} from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import close from "../../resources/images/ui/close-dark.svg"
import ExternalButton from "./ExternalButton"
import Button from "./Button"
import BoldText from "../minicart/BoldText"
import Upsell from "../minicart/Upsell"
import Item from "../minicart/Item"
import { getCart } from "../../services/cart/cart"
import { getCheckout } from "../../store/reducers"
import { connect } from "react-redux"
import { getLocalisedPrice } from "../../services/product/price"
import { isCartUpdating, isCartUpdated } from "../../store/reducers/index"

const OpenAnimation = () => keyframes`
  0% {
    right: -522px;
  }
  100% {
    right: 0;
  }
`;

const Overlay = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

const CartContainer = styled.div`
  width: 100%;
  max-width: 522px;
  height: 100%;
  max-height: 100vh;
  background: ${colors.white};
  padding: 84px 0px 161px;
  box-sizing: border-box;
  position: relative;
  cursor: default;
  animation: ${OpenAnimation} 0.3s linear 0s forwards;
  right: -522px;
  @media (min-width: 768px) {
    padding: 100px 0 166px;
  }
`

const CartTitle = styled.div`
  position: absolute;
  top: 0;
  padding: 40px 33px 0;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;
  left: 0%;
  background: ${colors.white};
  @media (min-width: 768px) {
    padding: 56px 50px 0;
  }
`

const TitleRow = styled.div`
  border-bottom: 1px solid ${colors.grey};
  padding-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

const CheckoutRow = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;
  left: 0%;
  padding: 0 33px 50px;
  @media (min-width: 768px) {
    padding: 0 50px 55px;
  }
`

const Checkout = styled.div`
  border-top: 1px solid ${colors.grey};
  padding-top: 24.5px;
  background: ${colors.white};
`

const ButtonContainer = styled.div`
  display: flex;
  margin: ${props => props.margin && "40px 0px"};
  div {
    width: 100% !important;
    text-align: center !important;
  }
`

const SubTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 21.5px;
`

const CartRow = styled.div`
  height: 100%;
  overflow-x: auto;
  padding: 0 33px;
  @media (min-width: 768px) {
    padding: 0 50px;
  }
`

const Empty = styled.div`
  padding-top: 40px;
  text-align: center;
  ${fonts.gilroyRegular};
  font-size: 15px;
  color: ${colors.navy};
`

const MiniCart = props => {
  const { open, handleCartOpen } = props
  let cartItems
  let cartData
  let cart
  let hasItems = false
  let checkoutUrl
  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    cartData = getCart()
  }

  if (cartData) {
    cart = JSON.parse(cartData)
    cartItems = cart.items
    hasItems = cartItems.length > 0 && true
    checkoutUrl = cart.checkoutUrl
  } else {
    cartItems = []
  }

  useEffect(() => {
    if (props.isAdded) {
      handleCartOpen(true)
    }
  }, [props.isAdded])

  const handleClick = () => {
    handleCartOpen(false)
  }

  const handleCheckout = (e) => {
    e.preventDefault()
    window.location.href = checkoutUrl 
  }


  return open ? (
    <Overlay onClick={() => handleClick()}>
      <CartContainer onClick={e => e.stopPropagation()}>
        <CartTitle>
          <TitleRow>
            <BoldText>My Net</BoldText>
            <CloseButton onClick={() => handleClick()}>
              <img src={close} alt="Close button" />
            </CloseButton>
          </TitleRow>
        </CartTitle>
        <CartRow>
          {hasItems ? (
            <>
              {cartItems.map((product, index) => {
                return <Item product={product} key={index} />
              })}
              <Upsell />
            </>
          ) : (
            <Empty>
              <div>Your basket is empty ...</div>
              <ButtonContainer margin>
                <Button text={"Continue shopping"} link={"/store"} dark />
              </ButtonContainer>
            </Empty>
          )}
        </CartRow>
        <CheckoutRow>
          {hasItems && (
            <Checkout>
              <SubTotal>
                <BoldText>Subtotal:</BoldText>
                <BoldText>
                  {cart
                    ? getLocalisedPrice(cart.subTotal, cart.currencyCode, false)
                    : 0.0}
                </BoldText>
              </SubTotal>
              <ButtonContainer>
                <ExternalButton
                  text={"Proceed to checkout"}
                  onClick={handleCheckout}
                  dark
                />
              </ButtonContainer>
            </Checkout>
          )}
        </CheckoutRow>
      </CartContainer>
    </Overlay>
  ) : null
}

const mapStateToProps = state => {
  return {
    cart: getCheckout(state),
    isAdding: isCartUpdating(state),
    isAdded: isCartUpdated(state),
  }
}

export default connect(mapStateToProps)(MiniCart)
