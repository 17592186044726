import React from 'react'
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"


const Text = styled.div`
  ${fonts.swissBlackExtended};
  color: ${colors.navy};
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 16px;
  text-transform: uppercase;
`

const BoldText = ({children}) => {
  return (
    <Text>
      {children}
    </Text>
  )
}

export default BoldText
