import React from 'react';
import styled from 'styled-components';


const OverlayStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    background-color: transparent;
`;
const TopOverlay = styled.div`
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to top, rgba(3, 10, 18, 0.0), rgba(3, 10, 18, 1))
`;

const OverlayTop = () => {
    return (<OverlayStyled>
        <TopOverlay />
    </OverlayStyled>);
}

export default OverlayTop;
