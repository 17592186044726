import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import DeleteImgMobile from "../../resources/images/net/delete-cross-mobile.svg"
import { Row } from "styled-bootstrap-grid"
import plus from "../../resources/images/ui/plus.svg"
import minus from "../../resources/images/ui/minus.svg"
import {
  updateItemAction,
  removeItemAction,
} from "../../store/actions/cart/index"
import {
  getRemovingItems,
  getRemovedItems,
  isCartUpdating,
} from "../../store/reducers"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { getProductLink } from "../../services/product/link"
import { getLocalisedPrice } from "../../services/product/price"

const ItemContainer = styled.div`
  padding: 25px 0;
  border-bottom: 1px dashed ${colors.grey};
`

const ItemImage = styled.img`
  /* width: 95px; */
  /* height: 100%; */
  height: 104px;
`

const ImageContainer = styled.div`
  position: relative;
  overflow-y: visible;
`

const DeleteButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 10;
  cursor: pointer;
`

const ItemDetail = styled.div`
  padding-left: 25px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`

const FirstRow = styled.div`
  padding-top: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`

const Name = styled.h3`
  ${fonts.gilroyRegular};
  color: ${colors.navy};
  font-size: 15px;
  line-height: 21px;
  a {
    color: ${colors.navy};
    text-decoration: none;
  }
`

const Price = styled.div`
  ${fonts.gilroyBold};
  color: ${colors.navy};
  font-size: 15px;
  line-height: normal;
  padding-left: 23px;
`

const Details = styled.div`
  padding: 0;
`

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
`

const SecondRow = styled.div`
  width: 100%;
`

const CountContainer = styled.div`
  border: 1px solid ${colors.grey};
  ${fonts.gilroyRegular};
  color: ${colors.navy};
  font-size: 15px;
  display: flex;
  width: min-content;
  align-items: center;
  span {
    padding: 0px 8px;
  }
`

const QuantityButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  img {
    padding: 15px;
  }
`

const Item = props => {
  const { product, updateItemAction, removeItemAction } = props
  const { lineItemId, quantity } = product

  const updateCartItem = quantity => {
    updateItemAction(lineItemId, quantity)
  }

  const removeItemFromCart = () => {
    removeItemAction(lineItemId)
  }

  const updateItem = quantityToAdd => {
    const { isUpdating } = props

    if (isUpdating) {
      return
    }

    const newQuantity = quantity + quantityToAdd

    if (newQuantity > 0) {
      updateCartItem(newQuantity)
    } else {
      removeItemFromCart()
    }
  }

  const addItem = e => {
    e.preventDefault()
    return updateItem(1)
  }

  const removeItem = e => {
    e.preventDefault()
    return updateItem(-1)
  }

  const deleteItem = e => {
    e.preventDefault()

    return removeItemFromCart()
  }

  return (
    <ItemContainer>
      <StyledRow>
        <ImageContainer>
          {product.image && (
            <Link to={getProductLink(product.handle)}>
              <ItemImage src={product.image} alt={product.title} />
            </Link>
          )}
          <DeleteButton onClick={e => deleteItem(e)}>
            <img src={DeleteImgMobile} alt="delete item" />
          </DeleteButton>
        </ImageContainer>
        <Details flex>
          <ItemDetail>
            <FirstRow>
              <Name>
                <Link to={getProductLink(product.handle)}>
                  {product.title}
                  {product.selectedOptions?.map((option, index) => {
                    return (
                      option.name === "Size" && (
                        <span key={index}>/{option.value}</span>
                      )
                    )
                  })}
                </Link>
              </Name>
              <Price>
                {getLocalisedPrice(product.price, product.currencyCode, false)}
              </Price>
            </FirstRow>
            <SecondRow>
              <CountContainer>
                <QuantityButton
                  onClick={e => removeItem(e)}
                  disabled={quantity <= 1}
                >
                  <img src={minus} alt="subtract quantity" />
                </QuantityButton>
                <span>{quantity}</span>
                <QuantityButton onClick={e => addItem(e)}>
                  <img src={plus} alt="increase quantity" />
                </QuantityButton>
              </CountContainer>
            </SecondRow>
          </ItemDetail>
        </Details>
      </StyledRow>
    </ItemContainer>
  )
}

const mapStateToProps = state => {
  return {
    isUpdating: isCartUpdating(state),
    removing: getRemovingItems(state),
    removed: getRemovedItems(state),
  }
}

const mapDispatchToProps = {
  updateItemAction,
  removeItemAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
