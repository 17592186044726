import React from 'react';
import styled from 'styled-components';
import InstagramIcon from '../../resources/images/icons/instagram.svg';
import FacebookIcon from '../../resources/images/icons/facebook.svg';
import TwitterIcon from '../../resources/images/icons/twitter.svg';
import VimeoIcon from '../../resources/images/icons/vimeo.svg';
import SpotifyIcon from '../../resources/images/icons/spotify.svg';

import InstagramIconDark from '../../resources/images/icons/instagram-dark.svg';
import FacebookIconDark from '../../resources/images/icons/facebook-dark.svg';
import TwitterIconDark from '../../resources/images/icons/twitter-dark.svg';
import VimeoIconDark from '../../resources/images/icons/vimeo-dark.svg';
import SpotifyIconDark from '../../resources/images/icons/spotify-dark.svg';
import ExternalLink from '../core/ExternalLink';
import colors from "../../styles/colors"

const BorderStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 38.4px;
    width: 38.4px;
    transform: scale(0.8333333) rotate(45deg);
    border: 1.5px solid transparent;
    transition: .3s;
    img {
      transform: scale(0.8333333) rotate(-45deg);
    }
    &:hover {
      border: 1.5px solid ${colors.mint};
      transform: scale(1) rotate(45deg);
      transition: .5s;
      img {
          transform: scale(1) rotate(-45deg);
          transition: .3s;
      }
    }
  `;

const SocialLinksContainer = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (min-width: 768px) {
        padding-left: 40px;
        margin-top: ${props => props.hero && '-24px'};
        margin-top: ${props => props.footer && '11px'};
        margin-top: ${props => props.jointhecrew && '-20px'};
        position: ${props => props.jointhecrew === "true" ? "relative" : "absolute"};
        left: ${props => props.paddingLeft ? props.paddingLeft: '0'};
        &.dark {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
        }
    }  
`;

const IconStyled = styled.img`
    height: ${props => props.color === "dark" ? "25.92px" : "18.72px"};
    transform: rotate(-45deg);
`;

const StlyedLinks = styled(ExternalLink)`
    margin-right: ${props => props.color === "dark" ? (props.size === 'large' ? "25px": "15px" ): (props.size === 'large' ? "19px": "1px" )};
    width: 40px;
    position: relative;
`;

const SocialMediaLinks = (props) => {
  const color = props.iconColor;
  const {paddingLeft, jointhecrew, footer, hero} = props

  let links = [
    {
      href: 'https://www.instagram.com/lostatsea_co/',
      icon: InstagramIcon,
      darkIcon: InstagramIconDark,
      size: 'default'
    },
    {
      href: 'https://www.facebook.com/lostatseaco',
      icon: FacebookIcon,
      darkIcon: FacebookIconDark,
      size: 'default'
    },
    {
      href: 'https://twitter.com/lostatsea_co',
      icon: TwitterIcon,
      darkIcon: TwitterIconDark,
      size: 'default'
    },
    {
      href: 'https://vimeo.com/lostatseabrewing',
      icon: VimeoIcon,
      darkIcon: VimeoIconDark,
      size: 'default'
    },
    {
      href: 'https://open.spotify.com/playlist/3DbuRz2EwYM1znENa0cVIZ',
      icon: SpotifyIcon,
      darkIcon: SpotifyIconDark,
      size: 'large'
    }
  ];

  return (
    <SocialLinksContainer className={color === 'dark' || color === 'basic' ? 'dark' : ''} jointhecrew={jointhecrew} paddingLeft={paddingLeft} footer={footer} hero={hero}>
      {
        links.map((item) => {
          return (
            <StlyedLinks href={item.href} color={color} key={item.href} className={'styledLink'}>
              <BorderStyled>
                <IconStyled color={color} src={color === "dark" ? item.darkIcon: item.icon} size={item.size}/>
              </BorderStyled>
            </StlyedLinks>
          )
        })
      }
    </SocialLinksContainer>);
};

export default SocialMediaLinks;
