import CartActionTypes from '../../actionTypes/cart';
import {getCart} from "../../reducers";
import {getCartItems, updateCart} from "../../../services/cart/cart";

const applyDiscountAction = (couponCode) => (dispatch, getState) => {
  if (!couponCode) {
    return;
  }

  const {checkout, client, isUpdating} = getCart(getState());
  
  if (isUpdating === true) {
    return;
  }

  if (!checkout) {
    console.error('A checkout is required');

    return;
  }
  
  dispatch({
    type: CartActionTypes.UPDATING_ACTION
  });
  
  const isBrowser = typeof window !== 'undefined';
  let items = [];

  if (isBrowser) {
    items = getCartItems();
  }

  const checkoutId = checkout.id;
  
  return client.checkout
    .addDiscount(checkoutId, couponCode)
    .then(checkout => {
      processCartUpdate(dispatch, items, checkout, couponCode);
    });
};

function processCartUpdate(dispatch, items, checkout, couponCode) {
  let cartData = updateCart(items, checkout);
  let cartCoupon = cartData.couponCode;
  let isPromoApplied = !!(cartCoupon && cartCoupon === couponCode);

  dispatch({
    type: CartActionTypes.UPDATE_ACTION,
    payload: {
      ...cartData,
      isUpdated: isPromoApplied
    }
  });
}

export {
  applyDiscountAction
}
