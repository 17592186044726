import React from "react";
import styled from 'styled-components';
import SignUpImg from '../../resources/images/ui/sign-up-btn.svg'
import fonts from '../../styles/fonts'


const SignUpImage = styled.img`
height: 26px;
width: 26px;
`;
const SignUpForm = styled.div`
    background: transparent;
`;
const BorderStyled = styled.div`
    position: absolute;
    top: 5px;
    right: -4px;
    background-color: transparent;
    border-bottom: 5px solid #9DDCE2;
    border-right: 5px solid #9DDCE2;
    height: 38px;
    width: 311px;
    z-index: -1;
`;
const BorderInputContainer = styled.div`
    position: relative;
    display: block;
    width: 318px;
    margin: 0 auto;
    input {
        background: transparent;
        border: 1px solid #9DDCE2;
        border-radius: 0px;
        ${fonts.gilroyRegular}
        color: white;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        width: 300px;
        padding: 10px 0 10px 15px;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        ::placeholder,
        ::-webkit-input-placeholder {
            color: white;
            font-size: 14px;
        }
        :-ms-input-placeholder {
            color: white;
            font-size: 14px;
        }
    }
    button {
        border: none !important;
        background-color: transparent !important;
        position: absolute;
        top: 8px;
        right: 8px;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }    
`;

const SimpleForm = ({ status, message, className, style, submit }) => {
  let input;
  const onSubmit = () =>
    input &&
    input.value.indexOf("@") > -1 &&
    submit({
      EMAIL: input.value
    });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <SignUpForm>
      <BorderInputContainer>
        <input
          onKeyPress={handleKeyPress}
          ref={node => (input = node)}
          type="email"
          placeholder="Your email address" />
        <button style={{ cursor: "pointer" }} onClick={onSubmit}><SignUpImage src={SignUpImg} /></button>
        <BorderStyled />
      </BorderInputContainer>
      <div style={{
        height: "25px", marginTop: "25px"
      }}>
        {status === "error" && (
          <div
            style={{
              color: "#9DDCE2",
              fontSize: "14px",
              fontWeight: "normal",
              fontFamily: "GilroyRegular",
              textAlign: "center"
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{
              color: "#9DDCE2",
              fontSize: "14px",
              fontWeight: "normal",
              fontFamily: "GilroyRegular",
              textAlign: "center"
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </SignUpForm>
  );
};

export default SimpleForm;

