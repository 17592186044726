const storage = require('../core/localStorage');

const LOCAL_STORAGE_CHECKOUT_ID_KEY = 'checkout_id';

function getCheckoutId() {
  return storage.getItem(LOCAL_STORAGE_CHECKOUT_ID_KEY);
}

function setCheckoutId(id) {
  storage.setItem(LOCAL_STORAGE_CHECKOUT_ID_KEY, id);
}

function clearCheckout() {
  storage.removeItem(LOCAL_STORAGE_CHECKOUT_ID_KEY);
}

module.exports = {
  getCheckoutId: getCheckoutId,
  setCheckoutId: setCheckoutId,
  clearCheckout: clearCheckout
};
