import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Slider from "react-slick"
import Img from "gatsby-image"
import BoldText from "./BoldText"
import { getProductLink } from "../../services/product/link"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { addItemAction } from "../../store/actions/cart/index"
import { isCartUpdating, isCartUpdated } from "../../store/reducers/index"
import {
  getOptionsData,
  getSelectedVariant,
} from "../../services/product/options"

const UpsellRow = styled.div`
  padding-top: 29.5px;
`

const Slide = styled.div`
  outline: none;
  text-align: center;
`

const StyledSlider = styled(Slider)`
  padding-top: 24px;
  padding-bottom: 40px;
  .slick-list {
    margin: 0 -12.5px;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    padding-bottom: 20px !important;
    position: relative;
  }
  .slick-slide > div {
    padding: 0 12.5px;
  }
`

const ProductTitle = styled.h3`
  ${fonts.gilroyRegular};
  color: ${colors.navy};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 17px 0;
`

const ButtonContainer = styled.div`
  padding: 0 3px 3px 3px;
  border-bottom: 1px solid ${colors.navy};
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const AddButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  ${fonts.gilroyBold};
  color: ${colors.navy};
  font-size: 12px;
  line-height: normal;
  letter-spacing: 2.77px;
  text-transform: uppercase;
  cursor: pointer;
`

const StyledImg = styled(Img)`
  max-height: 105px;
  img {
    object-fit: contain !important;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Upsell = props => {
  const { upsells } = useStaticQuery(
    graphql`
      query {
        upsells: allShopifyProduct(filter: { tags: { eq: "upsell" } }) {
          nodes {
            id
            handle
            title
            descriptionHtml
            options {
              name
              values
            }
            images {
              id
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 356, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  fixed(height: 173, quality: 80) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
            variants {
              id
              shopifyId
              selectedOptions {
                name
                value
              }
            }
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `
  )

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 400,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  }

  const addToCart = product => {
    const optionsData = getOptionsData(product)
    let variant = getSelectedVariant(product, optionsData)
    let variantImage

    if (product.images.length > 0) {
      const firstImage = product.images[0]

      if (firstImage.localFile) {
        variantImage = firstImage.localFile.url
      }
    }

    if (props.isUpdating) {
      return
    }

    props.addItemAction(variant, 1, variantImage)
  }

  return (
    <UpsellRow>
      <BoldText>Customers Also Bought</BoldText>
      <StyledSlider {...settings}>
        {upsells.nodes.map((product, index) => {
          const link = getProductLink(product.handle)
          return (
            <Slide key={index}>
              <div>
                <StyledLink to={link}>
                  {product.images.length > 0 && (
                    <StyledImg
                      fluid={product.images[0].localFile.childImageSharp.fluid}
                      alt={product.title}
                    />
                  )}
                  <ProductTitle>{product.title}</ProductTitle>
                </StyledLink>
                <ButtonContainer>
                  <AddButton onClick={() => addToCart(product)}>Add</AddButton>
                </ButtonContainer>
              </div>
            </Slide>
          )
        })}
      </StyledSlider>
    </UpsellRow>
  )
}

const mapStateToProps = state => {
  return {
    isAdding: isCartUpdating(state),
    isAdded: isCartUpdated(state),
  }
}

const mapDispatchToProps = {
  addItemAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Upsell)
