import React, { Component } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Link } from "gatsby"
import colors from "../../styles/colors"

const BorderStyled = styled.div`
  position: absolute;
  bottom: -5px;
  background-color: transparent;
  border-bottom: 5px solid ${colors.mint};
  border-right: 5px solid ${colors.mint};
  width: 100%;
  height: 90%;
  z-index: 0;
  display: ${props => (props.isVisible ? "block" : "none")};
  left: 5px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    left: 5px;
  }
`

const ButtonCotanier = styled.div`
  cursor: pointer;
  border: 1px solid ${colors.mint};
  background: transparent;
  color: ${colors.white};
  color: ${props => props.dark && colors.navy};
  text-transform: uppercase;
  font-size: 12px;
  ${fonts.gilroyBold};
  padding-top: 17px;
  padding-bottom: 15px;
  padding-left: 54px;
  padding-right: 54px;
  letter-spacing: 2.8px;
  line-height: 14px;
  transition: background 0.5s, border 0.5s, color 0.5s;
  -webkit-transition: background 0.5s, border 0.5s, color 0.5s;
  padding-left: ${props => props.shipmates && "31px"};
  padding-right: ${props => props.shipmates && "31px"};
  white-space: nowrap;
  position: relative;
  display: inline-block;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    background: ${colors.mint};
    border: 1px solid ${colors.mint};
    color: ${colors.navy};
  }
`

class Button extends Component {
  state = {
    isBorder: false,
  }

  componentDidMount() {
    this.toggleHover()
  }

  toggleHover = () => {
    this.setState({
      isBorder: !this.state.isBorder,
    })
  }

  render() {
    const { text, iscentered, dark, shipmates, onClick } = this.props
    return (
      <ButtonCotanier
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        dark={dark ? 1 : 0}
        shipmates={shipmates}
        onClick={e => onClick(e)}
      >
        {text}
        <BorderStyled
          isVisible={this.state.isBorder}
          iscentered={iscentered}
          shipmates={shipmates}
        />
      </ButtonCotanier>
    )
  }
}

export default Button
