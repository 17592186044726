function getItem(key) {
  return localStorage.getItem(key);
}

function setItem(key, data) {
  localStorage.setItem(key, data);
}

function removeItem(key) {
  localStorage.removeItem(key);
}

module.exports = {
  getItem: getItem,
  setItem: setItem,
  removeItem: removeItem
};
