const CURRENCY_CODE_EURO = 'EUR';

const symbols = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£'
};

function getCurrencySymbol(currencyCode) {
  const currencySymbol = symbols[currencyCode];
  
  if (currencySymbol) {
    return currencySymbol;
  }
  
  return null;
}

function formatPrice(price) {
  let parts = price.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
  return parts.join(".");
}

function getLocalisedPrice(price, currencyCode, round = true) {
  let priceString;
  const currencySymbol = getCurrencySymbol(currencyCode);
  
  if (!price) {
    price = 0;
  }
  
  if (round) {
    price = Math.ceil(price);
  } else {
    price = price
  }
  
  price = formatPrice(price);
  
  if (currencyCode === CURRENCY_CODE_EURO) {
    priceString = price + currencySymbol;
  } else {
    priceString = currencySymbol + price;
  }
  
  return priceString;
}


module.exports = {
  getLocalisedPrice: getLocalisedPrice,
};
