import CartActionTypes from '../../actionTypes/cart';
import {getCart} from "../../reducers";
import {getCartItems, updateCart} from "../../../services/cart/cart";

const removeItemAction = (lineItemId) => (dispatch, getState) => {
  if (!lineItemId) {
    console.error('Product line item ID is required');
    return;
  }
  
  const {checkout, client, removing, removed} = getCart(getState());
  
  if ((removing && removing.includes(lineItemId))
    || (removed && removed.includes(lineItemId))) {
    return;
  }
  
  if (!checkout) {
    console.error('A checkout is required');
    
    return;
  }
  
  removing.push(lineItemId);
  
  dispatch({
    type: CartActionTypes.UPDATING_ACTION,
    payload: {
      removing: removing
    }
  });

  const isBrowser = typeof window !== 'undefined';
  let items = [];

  if (isBrowser) {
    items = getCartItems();
  }

  const lineItemsToRemove = [lineItemId];
  const checkoutId = checkout.id;

  return client.checkout
    .removeLineItems(checkoutId, lineItemsToRemove)
    .then(checkout => {
      processCartUpdate(dispatch, items, checkout, lineItemId, removed);
    })
    .catch(error => {
      console.error(error.message);
    });
};

function processCartUpdate(dispatch, items, checkout, lineItemId, removed) {
  let cartData = updateCart(items, checkout);

  dispatch({
    type: CartActionTypes.UPDATE_ACTION,
    payload: {
      ...cartData,
      isUpdated: false
    }
  });

  removed.push(lineItemId);

  dispatch({
    type: CartActionTypes.UPDATED_ACTION,
    payload: {
      removed: removed
    }
  });
}

export {
  removeItemAction
}
