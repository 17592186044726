import React, {Component} from 'react';
import styled from 'styled-components';
import ShipImg from '../../resources/images/ui/ship-weel-grey.svg';
import fonts from '../../styles/fonts';
import { Link } from "gatsby";
import CartLink from './CartLink'

const NavbarStyled = styled.div`
    height: 40px;
    width: 100%;
    background-color: #222E3B;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 999;
    max-width: 100vw;
    @media (min-width: 768px) {
       display: flex;
       align-items: center;
    }
`;
const ShipImage = styled.img`
    height: 16px;
`;
const SignInText = styled.p`
    color: #9CA8B5;
    ${fonts.gilroyRegular};
    font-size: 12px;
    margin: 0;
    padding-left: 10px;
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
`

class Header extends Component {
  state = {};

  renderContent = () => {
    const {openCart} = this.props;
    return (
      <NavbarStyled fixed="top">
        <CartLink openCart={openCart}/>
      </NavbarStyled>);
  };

  render() {
    return this.renderContent();
  }

}

export default Header;
