function getOptionsData(product) {
  let options = product.options;

  let optionsData = {};

  if (product.variants.length === 1) {
    return optionsData;
  }

  options.forEach((option) => {
    optionsData[option.name] = option.values[0];
  });

  return optionsData;
}

function getSelectedVariant(product, optionsData) {
  const productVariants = product.variants;
  let selectedVariant = null;

  if (productVariants.length === 1) {
    return productVariants[0];
  }

  productVariants.forEach((variant) => {
    let selectedOptions = variant.selectedOptions;
    let isVariant = false;

    for (let i = 0; i < selectedOptions.length; i++) {
      let variantOption = selectedOptions[i];
      let optionName = variantOption.name;
      let optionValue = variantOption.value;

      if (optionsData[optionName] !== optionValue) {
        isVariant = false;

        break;
      }

      isVariant = true;
    }

    if (isVariant) {
      selectedVariant = variant;
    }
  });

  return selectedVariant;
}

module.exports = {
  getOptionsData: getOptionsData,
  getSelectedVariant: getSelectedVariant
};
