import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jsonp from 'jsonp';
import toQueryString from 'to-querystring';


const getAjaxUrl = url => url.replace('/post?', '/post-json?');

class MailchimpSubscribe extends Component {
  state = {
    status: null,
    message: null
  };
  subscribe = data => {
    const params = toQueryString(data);
    const url = getAjaxUrl(this.props.url) + '&' + params;
    this.setState(
      {
        status: 'sending',
        message: null
      },
      () =>
        jsonp(
          url,
          {
            param: 'c'
          },
          (err, data) => {
            if (err) {
              this.setState({
                status: 'error',
                message: err
              });
            } else if (data.result !== 'success') {
              this.setState({
                status: 'error',
                message: data.msg.replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
              });
            } else {
              this.setState({
                status: 'success',
                message: data.msg
              });
            }
          }
        )
    );
  };
  render() {
    const {subscribe} = this;
    const {status, message} = this.state;
    const {children} = this.props;

    const childrenComponent = React.cloneElement(children, {
      submit: subscribe,
      status: status,
      message: message
    });

    return (
      childrenComponent
    );
  }
}

export default MailchimpSubscribe;
