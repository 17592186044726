import React, { Component } from "react"
import posed from "react-pose"
import styled from "styled-components"
import LineImg from "../../resources/images/horizontal-broken-line.png"


const AnimatedLineAnimation = posed.div({
  start: {
    y: '0',
    transition: {
      type: 'spring',
      mass: 100,
      duration: 10
    }
  },
  end: {
    y: '15px',
    transition: {
      type: 'spring',
      mass: 10,
      duration: 500
    }
  }
});

const AnimatedLineStyled = styled(AnimatedLineAnimation)`
    background: url(${LineImg}) repeat-x;
    background-size: 80px auto;
    width: 80px;
    height: 8px;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 45px;
    @media(min-width: 768px) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    animation: slide 10s linear infinite;
    
    @keyframes slide {
      from { background-position: 0 0; }
      to { background-position: -80px 0; }
    }
`;

class AnimatedLine extends Component {
  state = {
    animate: 'start'
  };

  componentDidMount() {
    setInterval(() => {
      let animate = this.state.animate;

      if (animate === 'start') {
        animate = 'end';
      } else {
        animate = 'start';
      }

      this.setState({animate: animate});
    }, 1000);
  }

  render() {
    return <AnimatedLineStyled pose={this.state.animate}/>
  }
}

export default AnimatedLine