import React from "react"
import PropTypes from "prop-types"
import {navigate} from "gatsby"
import Cookies from 'universal-cookie';

const PrivateRoute = ({component: Component, ...props}) => {
  const cookies = new Cookies();
  let verified = false;
  
  if (typeof window !== 'undefined') {
    verified = cookies.get('legalAge');
  }

  if (!verified && typeof window !== 'undefined') {
    navigate('/age-verification');

    return null;
  }

  return <Component {...props} />
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
