import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ReactPopup from "reactjs-popup"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import { useStaticQuery, graphql } from "gatsby"
import CrowdcubeLogo from "../../../resources/images/investment/popup/crowdcube.svg"
import OnClickButtonMint from "../OnClickButtonMint"
import CloseIcon from "../../../resources/images/ui/popup-close-white.svg"
import Img from "gatsby-image"

const textColor = "#717B86"

const PopupContent = styled.div`
  padding: 56px 30px 52px;
  color: ${colors.white};
  text-align: center;
  position: relative;
  @media (min-width: 768px) {
    padding: 56px 56px 52px;
  }
`

const NewsLetterTitle = styled.h2`
  ${fonts.swissBlackExtended};
  font-size: 21px;
  letter-spacing: 1.3px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 20px;
`

const JoinTheCrew = styled.div`
  ${fonts.swissBlackExtended};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.74px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const Text = styled.div`
  ${fonts.gilroyRegular};
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 36px;
`

const TCPolicy = styled.div`
  ${fonts.gilroyRegular};
  font-size: 12px;
  line-height: 19px;
  margin: auto;
  margin-bottom: 31px;
  max-width: 320px;
`

const Partnership = styled.div`
  ${fonts.gilroyBold};
  font-size: 12px;
  letter-spacing: 2.77px;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

const InputContainer = styled.div`
  height: 55px;
  max-width: 350px;
  margin: auto;
  margin-bottom: 15px;
  input {
    ${fonts.gilroyRegular};
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    border: none;
    color: ${textColor};
    font-size: 15px;
    line-height: 21px;
    outline: none;
    ::placeholder {
      color: ${textColor};
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${textColor};
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: ${textColor};
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: ${textColor};
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: ${textColor};
    }
  }
`

const ButtonContainer = styled.div`
  margin: auto;
  margin-bottom: 24px;
  position: relative;
  button {
    margin-left: -5px;
    max-width: 345px;
  }
  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`

const StyledBackground = styled(Img)`
  background-color: ${colors.navy};
  cursor: default;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
`

const CloseButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  @media (min-width: 768px) {
    top: 20px;
    right: 20px;
  }
`

const Success = styled.div`
  ${fonts.swissBlackExtended};
  font-size: 21px;
  letter-spacing: 1.3px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 20px auto;
`

const Error = styled.div`
  ${fonts.gilroyRegular};
  position: absolute;
  color: "#9DDCE2";
  font-size: 10px;
  font-weight: "normal";
  text-align: center;
  color: ${colors.mint};
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 20px; 
  justify-content: center;
`

const contentStyle = {
  width: "552px",
  height: "auto",
  margin: "auto",
  marginRight: "auto",
  marginLeft: "auto",
  marginBottom: "auto",
  marginTop: "auto",
  background: "transparent",
  border: "none",
  paddingTop: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingBottom: "0px",
  boxSizing: "border-box",
  position: "relative",
}

const contentStyleMobile = {
  width: "calc(100% - 30px)",
  margin: "auto",
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  background: "transparent",
  border: "none",
  outline: "none",
  height: "auto",
  overflow: "auto",
  maxWidth: "552px",
  padding: "0",
}

const overlayStyle = {
  cursor: "pointer",
}

const NewsletterPopupContent = ({ submit, status, message }) => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "investment/popup/popup.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1012) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const popupStatus = () => {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem("popupseen") !== "seen") {
        return true
      } 
      if (localStorage.getItem("popupseen") === "seen") {
        return false
      } 
    }
  }

  const [email, setEmail] = useState(null)
  const [width, setWidth] = useState(0)
  const [open, setOpen] = useState(popupStatus())
  const { background } = data
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const handleSubmit = e => {
    e.preventDefault()
    email &&
      email.indexOf("@") > -1 &&
      submit({
        EMAIL: email,
      })
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const getContentStyle = () => {
    if (width >= 768) {
      return contentStyle
    } else {
      return contentStyleMobile
    }
  }

  const closePopup = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("popupseen", "seen")
      setOpen(false)
    }
  }

  return (
    <ReactPopup
      open={open}
      closeOnDocumentClick
      contentStyle={getContentStyle()}
      overlayStyle={overlayStyle}
      onClose={() => closePopup()}
    >
      <PopupContent>
        <StyledBackground
          fluid={background.childImageSharp.fluid}
          alt="Newsletter popup"
        />
        <CloseButton onClick={() => closePopup()}>
          <img src={CloseIcon} alt="Close newsletter popup" />
        </CloseButton>
        {status === "success" ? (
          <Success>{message}</Success>
        ) : (
          <>
            <NewsLetterTitle>Shares for shipmates</NewsLetterTitle>
            <JoinTheCrew>Join The Crew</JoinTheCrew>
            <Text>
              We’re crowdfunding a new adventure and you’re invited to get on
              board. Drop your email below and you’ll be notified first when our
              campaign sets sail.
            </Text>
            <form onSubmit={e => handleSubmit(e)} >
              <InputContainer>
                <input
                  placeholder={"Email address"}
                  type="email"
                  name={"email"}
                  id={"newsletter-email"}
                  onChange={e => setEmail(e.target.value)}
                />
              </InputContainer>
              <ButtonContainer type="submit">
                <OnClickButtonMint>Register Interest</OnClickButtonMint>
                {status === "error" && <Error>{message}</Error>}
              </ButtonContainer>
            </form>
            <TCPolicy>
              By signing up you are agreeing to the terms and conditions
              outlined in our privacy policy.
            </TCPolicy>
          </>
        )}
        <Partnership>In partnership with</Partnership>
        <img src={CrowdcubeLogo} alt="Crowdcube" />
      </PopupContent>
    </ReactPopup>
  )
}

export default NewsletterPopupContent
