import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import CartImg from "../../resources/images/ui/cart-grey.svg"
import fonts from "../../styles/fonts"
import { initAction } from "../../store/actions/cart"
import { connect } from "react-redux"
import { getItemsCount } from "../../store/reducers"

const CartImage = styled.img`
  height: 16px;
  padding-left: 20px;
`
const CartText = styled.p`
  color: #9ca8b5;
  ${fonts.gilroyRegular};
  font-size: 12px;
  margin: 0;
  padding-right: 40px;
  padding-left: 10px;
`

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`

const CartLink = (props) => {
  const {itemsCount, openCart} = props
  const fixedItemCount = itemsCount < 10 ? `0${itemsCount}` : itemsCount;

  useEffect(() => {
    if (typeof window !== "undefined") {
      props.initAction()
    }
  }, [])
  return (
    <StyledButton onClick={() => openCart(true)}>
      <CartImage src={CartImg} />
      <CartText>My Net ({fixedItemCount})</CartText>
    </StyledButton>
  )
}

const mapStateToProps = state => {
  return {
    itemsCount: getItemsCount(state),
  }
}

const mapDispatchToProps = {
  initAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartLink)
