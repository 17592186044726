import React from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from './MailchimpSubscribe';
import { Row, Col } from 'styled-bootstrap-grid';
import SimpleForm from "./SimpleForm"

const url = 'https://lostatsea.us3.list-manage.com/subscribe/post?u=55d481c245c181cbf26a7e965&amp;id=d533566af4';

const ColStyled = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

const RowStyled = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
`;

const SignUpFrom = () => {
  return (<RowStyled>
    <ColStyled md={6} mdOffset={3}>
      <MailchimpSubscribe url={url}>
        <SimpleForm />
      </MailchimpSubscribe>
    </ColStyled>
  </RowStyled>)
}

export default SignUpFrom;



