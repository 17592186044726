import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { Link } from "gatsby"

const StyledButton = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  background: ${props => (props.isBorder ? "transparent" : colors.mint)};
  text-transform: uppercase;
  font-size: 12px;
  ${fonts.gilroyBold};
  border: 1px solid ${colors.mint};
  height: 44px;
  width: 100%;
  text-align: center;
  color: ${props => (props.isBorder ? colors.mint : colors.navy)};
  letter-spacing: 2.77px;
  transition: background 0.3s, color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  &:active,
  &:focus {
    outline: none;
  }
`

const BorderStyled = styled.div`
  border: 1px solid ${colors.mint};
  width: 100%;
  height: 39px;
  right: -5px;
  bottom: -5px;
  position: absolute;
  display: ${props => (props.isVisible ? "block" : "none")};
`

const Button = ({ children, to }) => {
  const [isBorder, setIsBorder] = useState(false)

  const toggleHover = () => {
    setIsBorder(!isBorder)
  }
  return (
    <StyledButton
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleHover()}
      to={to}
      isBorder={isBorder}
    >
      {children}
      <BorderStyled isVisible={!isBorder} />
    </StyledButton>
  )
}

export default Button
