import React from 'react';

const ExternalLink = (props) => {
  const target = props.self ? "_self" : "_blank"
  return (
    <a href={props.href} target={target} rel="noopener noreferrer" className={props.className}>
      {props.children}
    </a>
  );
};

export default ExternalLink;
