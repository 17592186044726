const storage = require('../core/localStorage');

const LOCAL_STORAGE_CART_KEY = 'cart';

function getCartItems() {
  let items = [];
  const itemsData = getCart();

  if (itemsData) {
    const parsedItemsData = JSON.parse(itemsData);

    if (parsedItemsData && parsedItemsData.items) {
      items = parsedItemsData.items;
    }
  }

  return items;
}

function getTotal(cart) {
  return cart.totalPrice * 1;
}

function getSubTotal(cart) {
  return cart.subTotal * 1;
}

function updateCart(items, checkout, variantId = false, image = false) {
  let lineItems = checkout.lineItems;
  let itemsData = {};
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const lineItemId = item.lineItemId;

    itemsData[lineItemId] = item;
  }

  let itemsArray = [];
  lineItems.forEach((lineItem) => {
    const lineItemId = lineItem.id;
    const lineItemVariant = lineItem.variant;
    const lineItemVariantId = lineItemVariant.id;

    let itemData;
    if (itemsData[lineItemId]) {
      itemData = itemsData[lineItemId];
    } else {
      itemData = {};
    }

    itemData.lineItemId = lineItem.id;
    itemData.variantId = lineItemVariantId;
    itemData.title = lineItem.title;
    itemData.quantity = lineItem.quantity;
    itemData.price = lineItemVariant.price;
    itemData.currencyCode = lineItemVariant.priceV2.currencyCode;
    itemData.handle = lineItem.variant.product.handle;
    itemData.customAttributes = lineItem.customAttributes;

    let options = [];
    let selectedOptions = lineItemVariant.selectedOptions;

    selectedOptions.forEach((option) => {
      options.push({
        name: option.name,
        value: option.value
      })
    });

    itemData.selectedOptions = options;

    if (lineItemVariantId === variantId) {
      itemData.image = image;
    }

    itemsArray.push(itemData);
  });

  let discountApplications = checkout.discountApplications;
  let couponCode = null;

  if (discountApplications.length > 0) {
    couponCode = discountApplications[0].code;
  }

  let cartData = {
    items: itemsArray,
    checkout: checkout,
    checkoutUrl: checkout.webUrl,
    subTotal: checkout.subtotalPrice,
    estimatedShipping: checkout.estimatedShipping,
    estimatedTax: checkout.totalTax,
    taxesIncluded: checkout.taxesIncluded,
    totalPrice: checkout.totalPrice,
    currencyCode: checkout.currencyCode,
    couponCode: couponCode
  };

  setCartData(JSON.stringify(cartData));

  return cartData;
}

function getCart() {
  return storage.getItem(LOCAL_STORAGE_CART_KEY);
}

function setCartData(data) {
  storage.setItem(LOCAL_STORAGE_CART_KEY, data);
}

function clearCart() {
  storage.removeItem(LOCAL_STORAGE_CART_KEY);
}

module.exports = {
  getCart: getCart,
  getCartItems: getCartItems,
  getTotal: getTotal,
  getSubTotal: getSubTotal,
  updateCart: updateCart,
  clearCart: clearCart
};
